<template>
  <div>
    <div class="flex gap-20 justify-between dropdowns-search">
      <div
          class="bordered dropdown flex items-center"
          :class="min_filter && !limit_width ? 'min_search' : (limit_width ? 'next_min_search w-7/12' : 'w-2/3')"
          v-if="!isInPopup">
        <dropdown
            v-if="names.length > 1"
            id="company_names"
            class="pseudo-input text-left"
            :options="names"
            :selected="selected_name"
            :items_count="items_count"
            v-on:updateOption="selectSortFilterName"
        >
        </dropdown>
        <input v-else class="pseudo-input-no-drop text-left" v-model="selected_name.name" disabled type="text">
        <div class="search justify-between w-full flex px-4 items-center">
            <span class="search_input_span">
              <input class="search_input focus:outline-none" type="text" v-on:keyup.enter="searchItems" v-model="searchNames" placeholder="Search..">
            </span>
          <span class="icon" @click="searchItems">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18Z" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19 19L16 16" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
        </div>
      </div>
      <div class="flex items-center justify-between gap-5p rows-count" :class="limit_width ? 'w-5/12' : 'w-1/3'">
        <div class="bordered dropdown flex items-center">
          <dropdown
              id="rows_per_page"
              class="pseudo-input"
              :options="rows_per_page"
              :selected="{name: defaultRowsCount().name, id: defaultRowsCount().id}"
              v-on:updateOption="selectRowsCount"
          />
        </div>
        <div class="flex mt-11 items-center justify-between nowrap paginator">
            <span>
              {{this.fromNumber}} - {{this.toNumber}} of {{ items_count }}
            </span>
          <span class="arrows flex items-center justify-between nowrap">
            <button type="button"
                    :disabled="disabledPrev"
                    @click="nextPrevRows('-')"
                    class="inline-flex text-grey-darker paginate">
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 7L1 4L4 1" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
            <button type="button"
                    :disabled="disabledNext"
                    @click="nextPrevRows()"
                    class="inline-flex text-grey-darker paginate">
            <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L4 4L1 7" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
import {mapMutations, mapGetters} from "vuex";
export default {
  name: "Filters",
  props: {
    names: {
      type: Array
    },
    selected_name: {
      type: Object
    },
    items_count: {
      type: Number
    },
    rows_count: {
      type: Number,
      default: 10
    },
    isInPopup: {
      type: Boolean,
      default: false
    },
    min_filter: {
      type: Boolean,
      default: false
    },
    limit_width: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fromNumber: 1,
      toNumber: null,
      items_names:[],
      searchNames: null,
      default_rows_count: this.rows_count,
      disabledPrev: true,
      disabledNext: false,
      rows_per_page:[
        {
          name: '5 rows',
          id: 5
        },
        {
          name: '10 rows',
          id: 10
        },
        {
          name: '20 rows',
          id: 20
        },
        {
          name: '50 rows',
          id: 50
        },
        {
          name: '100 rows',
          id: 100
        }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setItemsToShow',
      'setSelectedFilterItem',
      'setRowsToShift',
      'setSearchItemsNames',
    ]),
    async searchItems() {
      if (this.searchNames.length >= 2 || !this.searchNames.length) {
        this.setSearchItemsNames(this.searchNames);
      }
    },
    async selectRowsCount(val) {
      this.setItemsToShow(val.id);
      this.default_rows_count = val.id;
      this.toNumber = val.id;
      this.fromNumber = 1;
      this.setRowsToShift(0);
      if(this.items_count <= val.id) {
        this.disabledNext = true;
      } else {
        this.disabledNext = false;
      }
    },
    selectSortFilterName(val){
      this.setSelectedFilterItem(val.id)
    },
    async nextPrevRows(operator = '+') {
      switch(operator) {
        case '+':
          if ((this.toNumber + this.getItemsToShow) > this.items_count) {
            this.toNumber = this.items_count;
            this.disabledNext = true;
          } else {
            this.toNumber = this.toNumber + this.getItemsToShow;
          }
          this.disabledPrev = false;
          if ((this.fromNumber + this.getItemsToShow) > this.items_count) {
            return;
          }
          this.fromNumber = this.fromNumber + this.getItemsToShow;
          this.setRowsToShift(this.fromNumber - 1);
          break;
        case '-':
          if(this.fromNumber == 1) {
            return;
          }
          this.disabledNext = false;
          if ((this.fromNumber - this.getItemsToShow) == 1) {
            this.toNumber = this.getItemsToShow;
            this.disabledPrev = true;
          } else {
            this.toNumber = this.toNumber - this.getItemsToShow;
          }
          this.fromNumber = this.fromNumber - this.default_rows_count;
          this.toNumber = this.fromNumber + this.default_rows_count -1;
          this.setRowsToShift(this.fromNumber - 1);
          break;
      }
    },
    defaultPaginator() {

    },
    defaultRowsCount(){
      return {name: this.default_rows_count + ' rows', id: this.default_rows_count};
    },
  },
  async mounted() {
    await this.setItemsToShow(this.default_rows_count)
    await this.setSelectedFilterItem(this.selected_name?.id)
    await this.defaultPaginator()
  },
  computed: {
    ...mapGetters([
      'getItemsToShow',
      'getSelectedFilterItem',
      'getRowsToShift',
    ]),
  },
  watch: {
    items_count(val) {
      this.toNumber = this.default_rows_count;
      if (this.items_count <= this.default_rows_count) {
        this.toNumber = val
        this.disabledPrev = true;
        this.disabledNext = true;
      }
    }
  },
  components: {
    dropdown
  }
}
</script>

<style lang="scss" scoped>
.arrows {
  width: 50px;
  .paginate {
    padding: 5px;
    &:disabled {
      cursor: not-allowed;
      svg {
        path {
          stroke: #9ca3af!important;
        }
      }
    }
  }
}
.min_search {
  width: 584px;
}
.next_min_search {
  width: 400px;
}
.pseudo-input-no-drop {
  margin-left: 10px;
  color: #606f7b;
  border-right: 2px solid #E5E5E5;
  max-height: 24px;
  max-width: 158px;
  background: white;
  padding-left: 5px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.search_input_span {
  width: 100%;
}
.search_input {
  width: 100%;
  //&:hover, &:focus, &:active {
  //  border: none !important;
  //}
}
</style>
